var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.isListLoading)?_c('v-row',{staticClass:"mt-5"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]):_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row py-1"},[_c('PageTitle',{staticClass:" ft font-weight-bold font-size-lg",attrs:{"text":"All Users"}})],1),_c('div',{staticClass:"d-flex flex-row mb-2"},[_c('v-btn',{staticClass:"ft font-weight-medium font-size-md ",attrs:{"to":{
          name: 'master.users.add',
          query: { action: 'add' }
        },"color":"primary"}},[_vm._v(" Add New User "),_c('i',{staticClass:"material-icons-outlined"},[_vm._v("add")])])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap mt-4"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-data-table',{staticClass:"ft font-weight-medium font-size-md box-shadow-light",attrs:{"headers":_vm.headers,"items":_vm.users,"footer-props":{
            itemsPerPageOptions: [15, 20, 30]
          }},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.isActive ? '#9efb9e66' : '#f98c6b',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                  color: item.isActive ? 'rgba(3, 91, 3, 0.83)' : '#862c00'
                })},[_vm._v(_vm._s(item.isActive ? "Active" : "Inactive"))])])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
                    name: 'master.users.add',
                    query: {
                      action: 'edit',
                      id: item.id
                    }
                  },"icon":"","color":"primary"}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Edit User")])])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }